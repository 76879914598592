import moment from "moment";
export const sortByLastMessage = (array) => {
  return !Array.isArray(array)
    ? console.log("Not array")
    : array.sort((a, b) => {
        return moment
          .utc(b.lastMessage?.createdAt)
          .diff(moment.utc(a.lastMessage?.createdAt));
      });
};
