export const statLabel = (key, translation) => {
  const labels = {
    averageSessionDuration: translation("Average Session Duration"),
    averageMessagesPerSession: translation("Average Messages Per Session"),
    retentionRate: translation("retention rate"),
    aiSuccessPerformanceRate: translation("AI Success Performance Rate"),
    aiFailurePerformanceRate: translation("AI Failure Performance Rate"),
    escalationRate: translation("Escalation Rate"),
    averageBotResponseTime: translation("Average Bot Response Time"),
  };

  return labels[key];
};
