export const dashToEmptySpace = (string) => {
  const array = string.split("-");
  let newString = "";
  array.forEach((item, index) => {
    if (index < array.length - 1) {
      newString = newString + item + "\xa0";
    } else {
      newString = newString + item;
    }
  });

  return newString;
};
