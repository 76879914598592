import { addDays } from "date-fns";

function getFirstDayOfWeek(d) {
  const date = new Date(d);
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
}
const today = new Date();

export const firstDay = getFirstDayOfWeek(today).toISOString().split("T")[0];

export const thisDay = new Date().toISOString().split("T")[0];
const beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
const day = beforeOneWeek.getDay();
const diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
export const lastMonday = new Date(beforeOneWeek.setDate(diffToMonday))
  .toISOString()
  .split("T")[0];

const my_date = new Date();
const currentMonth = new Date(my_date).getMonth() + 1;
const formattedDate = new Date(my_date.getFullYear(), my_date.getMonth(), 1)
  .toString()
  .split(" ");
export const firstDayOfMonth =
  formattedDate[3] + "-" + currentMonth + "-" + formattedDate[2];

export const lastDayOfWeek = new Date(addDays(new Date(firstDay), 6))
  .toISOString()
  .toString()
  .substr(0, 10);

export const formatDate = (date) =>
  new Date(date).toISOString().toString().substr(0, 10);
