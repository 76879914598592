export const formatData = (string) => {
  let str = string + "";
  let formattedData = "";
  if (str?.includes(".")) {
    formattedData += str?.split(".")[0] + "." + str?.split(".")[1][0];
  } else {
    formattedData = str;
  }
  return formattedData;
};
