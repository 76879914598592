export const emptyKeyOrValue = (arr) => {
  let isEmptyStringFound = false;
  arr.forEach((obj) => {
    Object.values(obj).forEach((value) => {
      if (value === "") {
        isEmptyStringFound = true;
      }
    });
  });
  return isEmptyStringFound;
};
