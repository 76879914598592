import dayjs from "dayjs";

export const validateBotConfig = (values) => {
  let isDirty = true;
  values.days.forEach((el) => {
    el.day.workInterval.forEach(({ startDate, endDate }) => {
      const StartDate =
        typeof startDate === "string"
          ? convertHoursAndMinutesToDate(startDate)
          : startDate;

      const EndDate =
        typeof endDate === "string"
          ? convertHoursAndMinutesToDate(endDate)
          : endDate;

      (!StartDate || !EndDate || !dayjs(StartDate).isBefore(dayjs(EndDate))) &&
        (isDirty = false);
    });
  });
  return isDirty;
};

const convertHoursAndMinutesToDate = (date) => {
  // Split the time into hours and minutes
  const [hours, minutes] = date.split(":");

  // Create a new Date object with a default date (e.g., today's date)
  const currentDate = new Date();

  // Set the hours and minutes from the extracted values
  currentDate.setHours(hours, minutes);

  return currentDate;
};
