export const findInValues = (arr, search) => {
  /** cast to string and lowercase to have non-dependant type and case search */
  search = String(search)?.toLowerCase();
  return arr?.filter((object) =>
    /** get only values from iterated objects */
    Object?.values(object)?.some((objValue) =>
      /** casting field values to the same shape */
      {
        return String(objValue)?.toLowerCase()?.includes(search);
      }
    )
  );
};
