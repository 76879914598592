export const getObjectDiffAttributes = (obj1, obj2) => {
  const diffAttributes = [];
  if (!obj2) {
    const { id, ...rest } = obj1;
    diffAttributes.push(Object.keys(rest)[0]);
  } else {
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        if (!obj2.hasOwnProperty(key)) {
          diffAttributes.push(key);
        }
      }
    }
  }
  return diffAttributes;
};
