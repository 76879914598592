export function getLastFalseNoteObject(table) {
  let output = null;
  // Iterate through the table in reverse order, excluding the last object
  for (let i = table.length - 2; i >= 0; i--) {
    if (!table[i].isNote) {
      output = table[i];
      break;
    }
  }
  return output;
}
