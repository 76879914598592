export function toEnglishNumber(strNum) {
  var ar = "٠١٢٣٤٥٦٧٨٩".split("");
  var en = "0123456789".split("");
  strNum = strNum.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (x) => en[ar.indexOf(x)]);
  strNum = strNum.replace(/[^\d]/g, "");
  return strNum;
}

export const ConvertingArabicNumberToEnglish = (string) => {
  return string.replace(/[\u0660-\u0669\u06f0-\u06f9]/g, function (c) {
    return c.charCodeAt(0) & 0xf;
  });
};
