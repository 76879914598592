export const embedVideoCallBack = (link) => {
  if (link.indexOf("youtube") >= 0) {
    link = link.replace("watch?v=", "embed/").split("&")[0];
    link = link.replace("/watch/", "/embed/");
    link = link.replace("youtu.be/", "youtube.com/embed/");
    return link;
  } else {
    return "";
  }
};
