export const toFixedTwo = (value) => {
  const valueString = value.toString();
  const decimalPart = valueString.split(".")[1];

  // Check if the decimal part exists and has more than two digits
  if (decimalPart && decimalPart.length > 2) {
    return value.toFixed(2);
  } else {
    return value;
  }
};
